<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <card body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h2 class="mb-0">{{ getInfoSelectedTrainer.name }} Courses</h2>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
              <div class="wrapper-select">
                <span>Show</span>
                <div class="select-row">
                  <el-select
                    class="select-primary pagination-select"
                    size="mini"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
                <span>Row</span>
              </div>
              <div class="form-search">
                <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
              </div>
            </div>
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
              <el-table-column prop="status" label="Status" width="150">
                <template slot-scope="scope">
                  <el-tag
                    disable-transitions
                    :class="statusClass(scope.row.status)"
                  >{{ scope.row.status ? "Active" : "Nonactive" }}</el-tag>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span
                  v-if="selectedRows.length"
                >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Tag } from 'element-ui'
import { BasePagination } from '@/components';
import { getListCourseTrainer } from '@/services/trainer';
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import Fuse from "fuse.js";
import listCoursesMixin from './Mixins/listCoursesMixin'
import { mapGetters } from 'vuex'
export default {
  mixins: [listCoursesMixin],
  name: "listCourses",
  components: {
    BasePagination,
    DashboardNavbar,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tag.name]: Tag
  },
  data() {
    return {
      propsToSearch: ['updatedAt', 'name', 'category.name', 'participant', 'rating'],
      tableColumns: [
        {
          prop: 'updatedAt',
          label: 'Date Update',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'name',
          label: 'Title Course',
          minWidth: 250,
          sortable: true
        },
        {
          prop: 'category.name',
          label: 'Category Course',
          minWidth: 160,
          sortable: true
        },
        {
          prop: 'participant',
          label: 'Number Participant',
          minWidth: 160,
          sortable: true
        },
        {
          prop: 'rating',
          label: 'Rating',
          minWidth: 80,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      routes: [
        {
          name: "ListTrainers",
          alias: "List-Trainers"
        },
        {
          name: "trainerCourses",
          alias: "DETAIL COURSES"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("trainer", ["getInfoSelectedTrainer"]),
  },
  methods: {
    statusClass(status) {
      return status ? "active" : "nonactive"
    },
    handleEdit(index, row) {
      this.$router.push({ name: 'editTrainer', params: { id: row.id } })
    },
    handleCourse(index, row) {
      this.$router.push({ name: 'ListStudentsCourse', params: { id: row.id } })
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    async getListCourseByTrainer() {
      try {
        const { data } = await getListCourseTrainer(this.$route.params.id)
        this.tableData = data,
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  },
  mounted() {
    this.getListCourseByTrainer()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.pagination-select {
  margin: 0 5px;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}

.el-tag {
  background-color: #fff !important;
  border-color: #fff !important;
  &.active {
    color: $status-pass-ar-vr;
  }
  &.nonactive {
    color: $status-failed-ar-vr;
  }
}
</style>
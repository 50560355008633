import { api } from "./axios";

const getAllTrainer = () => {
  return api.get("v1/trainer");
};

const getListBank = () => {
  return api.get("v1/bank");
};

const getDetailTrainer = id => {
  return api.get(`v1/trainer/${id}`);
};

const getListCourseTrainer = id => {
  return api.get(`v1/trainer/course/${id}`);
};

const addNewTrainer = data => {
  return api.post(`v1/trainer`, data);
};

const updateDataTrainer = data => {
  return api.patch(`v1/trainer`, data);
};

export {
  getListBank,
  getAllTrainer,
  getDetailTrainer,
  getListCourseTrainer,
  addNewTrainer,
  updateDataTrainer
};
